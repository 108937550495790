import globalAxios from 'axios';

/* tslint:disable */
/* eslint-disable */
/**
 * thinhoc-api
 * API for ThinHoc Cloud Management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mail@thinhoc.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends$1 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var BASE_PATH = "http://localhost".replace(/\/+$/, "");
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, axios) {
        if (basePath === void 0) { basePath = BASE_PATH; }
        if (axios === void 0) { axios = globalAxios; }
        this.basePath = basePath;
        this.axios = axios;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
    return BaseAPI;
}());
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends$1(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        _this.name = "RequiredError";
        return _this;
    }
    return RequiredError;
}(Error));

/* tslint:disable */
/* eslint-disable */
/**
 * thinhoc-api
 * API for ThinHoc Cloud Management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mail@thinhoc.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign$1 = (undefined && undefined.__assign) || function () {
    __assign$1 = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign$1.apply(this, arguments);
};
var __awaiter$1 = (undefined && undefined.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator$1 = (undefined && undefined.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 *
 * @export
 */
var DUMMY_BASE_URL = 'https://example.com';
/**
 *
 * @throws {RequiredError}
 * @export
 */
var assertParamExists = function (functionName, paramName, paramValue) {
    if (paramValue === null || paramValue === undefined) {
        throw new RequiredError(paramName, "Required parameter " + paramName + " was null or undefined when calling " + functionName + ".");
    }
};
/**
 *
 * @export
 */
var setBearerAuthToObject = function (object, configuration) {
    return __awaiter$1(this, void 0, void 0, function () {
        var accessToken, _a;
        return __generator$1(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(configuration && configuration.accessToken)) return [3 /*break*/, 5];
                    if (!(typeof configuration.accessToken === 'function')) return [3 /*break*/, 2];
                    return [4 /*yield*/, configuration.accessToken()];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, configuration.accessToken];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    accessToken = _a;
                    object["Authorization"] = "Bearer " + accessToken;
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    });
};
/**
 *
 * @export
 */
var setSearchParams = function (url) {
    var objects = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        objects[_i - 1] = arguments[_i];
    }
    var searchParams = new URLSearchParams(url.search);
    for (var _a = 0, objects_1 = objects; _a < objects_1.length; _a++) {
        var object = objects_1[_a];
        for (var key in object) {
            if (Array.isArray(object[key])) {
                searchParams.delete(key);
                for (var _b = 0, _c = object[key]; _b < _c.length; _b++) {
                    var item = _c[_b];
                    searchParams.append(key, item);
                }
            }
            else {
                searchParams.set(key, object[key]);
            }
        }
    }
    url.search = searchParams.toString();
};
/**
 *
 * @export
 */
var serializeDataIfNeeded = function (value, requestOptions, configuration) {
    var nonString = typeof value !== 'string';
    var needsSerialization = nonString && configuration && configuration.isJsonMime
        ? configuration.isJsonMime(requestOptions.headers['Content-Type'])
        : nonString;
    return needsSerialization
        ? JSON.stringify(value !== undefined ? value : {})
        : (value || "");
};
/**
 *
 * @export
 */
var toPathString = function (url) {
    return url.pathname + url.search + url.hash;
};
/**
 *
 * @export
 */
var createRequestFunction = function (axiosArgs, globalAxios, BASE_PATH, configuration) {
    return function (axios, basePath) {
        if (axios === void 0) { axios = globalAxios; }
        if (basePath === void 0) { basePath = BASE_PATH; }
        var axiosRequestArgs = __assign$1(__assign$1({}, axiosArgs.options), { url: ((configuration === null || configuration === void 0 ? void 0 : configuration.basePath) || basePath) + axiosArgs.url });
        return axios.request(axiosRequestArgs);
    };
};

/* tslint:disable */
/* eslint-disable */
/**
 * thinhoc-api
 * API for ThinHoc Cloud Management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mail@thinhoc.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (undefined && undefined.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (undefined && undefined.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (undefined && undefined.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
    * @export
    * @enum {string}
    */
var OrganizationMgmtTierEnum;
(function (OrganizationMgmtTierEnum) {
    OrganizationMgmtTierEnum["Free"] = "free";
    OrganizationMgmtTierEnum["Pro"] = "pro";
})(OrganizationMgmtTierEnum || (OrganizationMgmtTierEnum = {}));
/**
    * @export
    * @enum {string}
    */
var OrganizationWithRelationsMgmtTierEnum;
(function (OrganizationWithRelationsMgmtTierEnum) {
    OrganizationWithRelationsMgmtTierEnum["Free"] = "free";
    OrganizationWithRelationsMgmtTierEnum["Pro"] = "pro";
})(OrganizationWithRelationsMgmtTierEnum || (OrganizationWithRelationsMgmtTierEnum = {}));
/**
 * CMDemoControllerApi - axios parameter creator
 * @export
 */
var CMDemoControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cMDemoControllerDemo: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/demo";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (email !== undefined) {
                                localVarQueryParameter['email'] = email;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * CMDemoControllerApi - functional programming interface
 * @export
 */
var CMDemoControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = CMDemoControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cMDemoControllerDemo: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cMDemoControllerDemo(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CMDemoControllerApi - factory interface
 * @export
 */
var CMDemoControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = CMDemoControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cMDemoControllerDemo: function (email, options) {
            return localVarFp.cMDemoControllerDemo(email, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CMDemoControllerApi - object-oriented interface
 * @export
 * @class CMDemoControllerApi
 * @extends {BaseAPI}
 */
var CMDemoControllerApi = /** @class */ (function (_super) {
    __extends(CMDemoControllerApi, _super);
    function CMDemoControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMDemoControllerApi
     */
    CMDemoControllerApi.prototype.cMDemoControllerDemo = function (email, options) {
        var _this = this;
        return CMDemoControllerApiFp(this.configuration).cMDemoControllerDemo(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CMDemoControllerApi;
}(BaseAPI));
/**
 * MachineControllerApi - axios parameter creator
 * @export
 */
var MachineControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFind: function (filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/machines";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {MachineFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFindById: function (id, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerFindById', 'id', id);
                            localVarPath = "/machines/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFindContextById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerFindContextById', 'id', id);
                            localVarPath = "/machines/{id}/context"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [count]
         * @param {boolean} [debug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerLogsById: function (id, count, debug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerLogsById', 'id', id);
                            localVarPath = "/machines/{id}/logs"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (count !== undefined) {
                                localVarQueryParameter['count'] = count;
                            }
                            if (debug !== undefined) {
                                localVarQueryParameter['debug'] = debug;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerNotifyById: function (id, inlineObject1, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerNotifyById', 'id', id);
                            localVarPath = "/machines/{id}/notify"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerPatchProfileById: function (id, inlineObject2, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerPatchProfileById', 'id', id);
                            localVarPath = "/machines/{id}/profile"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerPoweroffById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerPoweroffById', 'id', id);
                            localVarPath = "/machines/{id}/poweroff"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRebootById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerRebootById', 'id', id);
                            localVarPath = "/machines/{id}/reboot"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {{ [key: string]: object; }} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRegister: function (id, requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerRegister', 'id', id);
                            localVarPath = "/machines/{id}/register"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRemoteControl: function (id, inlineObject3, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerRemoteControl', 'id', id);
                            localVarPath = "/machines/{id}/remote"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRenameById: function (id, inlineObject, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerRenameById', 'id', id);
                            localVarPath = "/machines/{id}/name"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerReplaceContextById: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerReplaceContextById', 'id', id);
                            localVarPath = "/machines/{id}/context"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerTokenVerify: function (id, inlineObject4, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('machineControllerTokenVerify', 'id', id);
                            localVarPath = "/machines/{id}/token-verify"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * MachineControllerApi - functional programming interface
 * @export
 */
var MachineControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = MachineControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFind: function (filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerFind(filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {MachineFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFindById: function (id, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerFindById(id, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFindContextById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerFindContextById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {number} [count]
         * @param {boolean} [debug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerLogsById: function (id, count, debug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerLogsById(id, count, debug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerNotifyById: function (id, inlineObject1, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerNotifyById(id, inlineObject1, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerPatchProfileById: function (id, inlineObject2, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerPatchProfileById(id, inlineObject2, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerPoweroffById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerPoweroffById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRebootById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerRebootById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {{ [key: string]: object; }} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRegister: function (id, requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerRegister(id, requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRemoteControl: function (id, inlineObject3, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerRemoteControl(id, inlineObject3, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRenameById: function (id, inlineObject, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerRenameById(id, inlineObject, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerReplaceContextById: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerReplaceContextById(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerTokenVerify: function (id, inlineObject4, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.machineControllerTokenVerify(id, inlineObject4, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * MachineControllerApi - factory interface
 * @export
 */
var MachineControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = MachineControllerApiFp(configuration);
    return {
        /**
         *
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFind: function (filter, options) {
            return localVarFp.machineControllerFind(filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {MachineFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFindById: function (id, filter, options) {
            return localVarFp.machineControllerFindById(id, filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerFindContextById: function (id, options) {
            return localVarFp.machineControllerFindContextById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {number} [count]
         * @param {boolean} [debug]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerLogsById: function (id, count, debug, options) {
            return localVarFp.machineControllerLogsById(id, count, debug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerNotifyById: function (id, inlineObject1, options) {
            return localVarFp.machineControllerNotifyById(id, inlineObject1, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerPatchProfileById: function (id, inlineObject2, options) {
            return localVarFp.machineControllerPatchProfileById(id, inlineObject2, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerPoweroffById: function (id, options) {
            return localVarFp.machineControllerPoweroffById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRebootById: function (id, options) {
            return localVarFp.machineControllerRebootById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {{ [key: string]: object; }} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRegister: function (id, requestBody, options) {
            return localVarFp.machineControllerRegister(id, requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRemoteControl: function (id, inlineObject3, options) {
            return localVarFp.machineControllerRemoteControl(id, inlineObject3, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerRenameById: function (id, inlineObject, options) {
            return localVarFp.machineControllerRenameById(id, inlineObject, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerReplaceContextById: function (id, body, options) {
            return localVarFp.machineControllerReplaceContextById(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} id
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        machineControllerTokenVerify: function (id, inlineObject4, options) {
            return localVarFp.machineControllerTokenVerify(id, inlineObject4, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * MachineControllerApi - object-oriented interface
 * @export
 * @class MachineControllerApi
 * @extends {BaseAPI}
 */
var MachineControllerApi = /** @class */ (function (_super) {
    __extends(MachineControllerApi, _super);
    function MachineControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {MachineFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerFind = function (filter, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerFind(filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {MachineFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerFindById = function (id, filter, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerFindById(id, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerFindContextById = function (id, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerFindContextById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {number} [count]
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerLogsById = function (id, count, debug, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerLogsById(id, count, debug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerNotifyById = function (id, inlineObject1, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerNotifyById(id, inlineObject1, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerPatchProfileById = function (id, inlineObject2, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerPatchProfileById(id, inlineObject2, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerPoweroffById = function (id, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerPoweroffById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerRebootById = function (id, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerRebootById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {{ [key: string]: object; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerRegister = function (id, requestBody, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerRegister(id, requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerRemoteControl = function (id, inlineObject3, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerRemoteControl(id, inlineObject3, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerRenameById = function (id, inlineObject, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerRenameById(id, inlineObject, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerReplaceContextById = function (id, body, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerReplaceContextById(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} id
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineControllerApi
     */
    MachineControllerApi.prototype.machineControllerTokenVerify = function (id, inlineObject4, options) {
        var _this = this;
        return MachineControllerApiFp(this.configuration).machineControllerTokenVerify(id, inlineObject4, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return MachineControllerApi;
}(BaseAPI));
/**
 * OrganizationBillingControllerApi - axios parameter creator
 * @export
 */
var OrganizationBillingControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationBillingControllerCreateBillingAccount: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationBillingControllerCreateBillingAccount', 'id', id);
                            localVarPath = "/organizations/{id}/billing"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationBillingControllerFindBillingInformationById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationBillingControllerFindBillingInformationById', 'id', id);
                            localVarPath = "/organizations/{id}/billing"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * OrganizationBillingControllerApi - functional programming interface
 * @export
 */
var OrganizationBillingControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = OrganizationBillingControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationBillingControllerCreateBillingAccount: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationBillingControllerCreateBillingAccount(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationBillingControllerFindBillingInformationById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationBillingControllerFindBillingInformationById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * OrganizationBillingControllerApi - factory interface
 * @export
 */
var OrganizationBillingControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = OrganizationBillingControllerApiFp(configuration);
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationBillingControllerCreateBillingAccount: function (id, options) {
            return localVarFp.organizationBillingControllerCreateBillingAccount(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationBillingControllerFindBillingInformationById: function (id, options) {
            return localVarFp.organizationBillingControllerFindBillingInformationById(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * OrganizationBillingControllerApi - object-oriented interface
 * @export
 * @class OrganizationBillingControllerApi
 * @extends {BaseAPI}
 */
var OrganizationBillingControllerApi = /** @class */ (function (_super) {
    __extends(OrganizationBillingControllerApi, _super);
    function OrganizationBillingControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationBillingControllerApi
     */
    OrganizationBillingControllerApi.prototype.organizationBillingControllerCreateBillingAccount = function (id, options) {
        var _this = this;
        return OrganizationBillingControllerApiFp(this.configuration).organizationBillingControllerCreateBillingAccount(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationBillingControllerApi
     */
    OrganizationBillingControllerApi.prototype.organizationBillingControllerFindBillingInformationById = function (id, options) {
        var _this = this;
        return OrganizationBillingControllerApiFp(this.configuration).organizationBillingControllerFindBillingInformationById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return OrganizationBillingControllerApi;
}(BaseAPI));
/**
 * OrganizationControllerApi - axios parameter creator
 * @export
 */
var OrganizationControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerAcceptInvitation: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerAcceptInvitation', 'id', id);
                            localVarPath = "/organizations/{id}/accept"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate: function (organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/organizations";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDeleteById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerDeleteById', 'id', id);
                            localVarPath = "/organizations/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {InlineObject6} [inlineObject6]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDeleteMember: function (id, inlineObject6, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerDeleteMember', 'id', id);
                            localVarPath = "/organizations/{id}/member"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject6, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindAuthSettingsById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerFindAuthSettingsById', 'id', id);
                            localVarPath = "/organizations/{id}/auth"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {OrganizationFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindById: function (id, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerFindById', 'id', id);
                            localVarPath = "/organizations/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetInvitations: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/organizations/invitations";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetUsers: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerGetUsers', 'id', id);
                            localVarPath = "/organizations/{id}/users"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInviteUser: function (id, inlineObject5, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerInviteUser', 'id', id);
                            localVarPath = "/organizations/{id}/invite"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerLeaveOrganization: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerLeaveOrganization', 'id', id);
                            localVarPath = "/organizations/{id}/leave"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerReplaceAuthSettingsById: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerReplaceAuthSettingsById', 'id', id);
                            localVarPath = "/organizations/{id}/auth"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerTest: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerTest', 'id', id);
                            localVarPath = "/organizations/{id}/billing-information"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateById: function (id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerUpdateById', 'id', id);
                            localVarPath = "/organizations/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {string} [mgmtTier]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateMGMTTierById: function (id, mgmtTier, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('organizationControllerUpdateMGMTTierById', 'id', id);
                            localVarPath = "/organizations/{id}/mgmt-tier"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (mgmtTier !== undefined) {
                                localVarQueryParameter['mgmtTier'] = mgmtTier;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * OrganizationControllerApi - functional programming interface
 * @export
 */
var OrganizationControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = OrganizationControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerAcceptInvitation: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerAcceptInvitation(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate: function (organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerCreate(organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDeleteById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerDeleteById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {InlineObject6} [inlineObject6]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDeleteMember: function (id, inlineObject6, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerDeleteMember(id, inlineObject6, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindAuthSettingsById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerFindAuthSettingsById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {OrganizationFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindById: function (id, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerFindById(id, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetInvitations: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerGetInvitations(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetUsers: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerGetUsers(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInviteUser: function (id, inlineObject5, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerInviteUser(id, inlineObject5, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerLeaveOrganization: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerLeaveOrganization(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerReplaceAuthSettingsById: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerReplaceAuthSettingsById(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerTest: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerTest(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateById: function (id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerUpdateById(id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {string} [mgmtTier]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateMGMTTierById: function (id, mgmtTier, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationControllerUpdateMGMTTierById(id, mgmtTier, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * OrganizationControllerApi - factory interface
 * @export
 */
var OrganizationControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = OrganizationControllerApiFp(configuration);
    return {
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerAcceptInvitation: function (id, options) {
            return localVarFp.organizationControllerAcceptInvitation(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate: function (organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
            return localVarFp.organizationControllerCreate(organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDeleteById: function (id, options) {
            return localVarFp.organizationControllerDeleteById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {InlineObject6} [inlineObject6]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDeleteMember: function (id, inlineObject6, options) {
            return localVarFp.organizationControllerDeleteMember(id, inlineObject6, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindAuthSettingsById: function (id, options) {
            return localVarFp.organizationControllerFindAuthSettingsById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {OrganizationFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerFindById: function (id, filter, options) {
            return localVarFp.organizationControllerFindById(id, filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetInvitations: function (options) {
            return localVarFp.organizationControllerGetInvitations(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGetUsers: function (id, options) {
            return localVarFp.organizationControllerGetUsers(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerInviteUser: function (id, inlineObject5, options) {
            return localVarFp.organizationControllerInviteUser(id, inlineObject5, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerLeaveOrganization: function (id, options) {
            return localVarFp.organizationControllerLeaveOrganization(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerReplaceAuthSettingsById: function (id, body, options) {
            return localVarFp.organizationControllerReplaceAuthSettingsById(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerTest: function (id, options) {
            return localVarFp.organizationControllerTest(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateById: function (id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
            return localVarFp.organizationControllerUpdateById(id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {string} [mgmtTier]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateMGMTTierById: function (id, mgmtTier, options) {
            return localVarFp.organizationControllerUpdateMGMTTierById(id, mgmtTier, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * OrganizationControllerApi - object-oriented interface
 * @export
 * @class OrganizationControllerApi
 * @extends {BaseAPI}
 */
var OrganizationControllerApi = /** @class */ (function (_super) {
    __extends(OrganizationControllerApi, _super);
    function OrganizationControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerAcceptInvitation = function (id, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerAcceptInvitation(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerCreate = function (organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerCreate(organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerDeleteById = function (id, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerDeleteById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerDeleteMember = function (id, inlineObject6, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerDeleteMember(id, inlineObject6, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerFindAuthSettingsById = function (id, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerFindAuthSettingsById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {OrganizationFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerFindById = function (id, filter, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerFindById(id, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerGetInvitations = function (options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerGetInvitations(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerGetUsers = function (id, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerGetUsers(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerInviteUser = function (id, inlineObject5, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerInviteUser(id, inlineObject5, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerLeaveOrganization = function (id, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerLeaveOrganization(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerReplaceAuthSettingsById = function (id, body, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerReplaceAuthSettingsById(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerTest = function (id, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerTest(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {OrganizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket} [organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerUpdateById = function (id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerUpdateById(id, organizationOptionalMembersExcludingIdMgmtTierAuthenticationSettingsBillingAccountIdS3RegionS3Bucket, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {string} [mgmtTier]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.organizationControllerUpdateMGMTTierById = function (id, mgmtTier, options) {
        var _this = this;
        return OrganizationControllerApiFp(this.configuration).organizationControllerUpdateMGMTTierById(id, mgmtTier, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return OrganizationControllerApi;
}(BaseAPI));
/**
 * OrganizationProfileControllerApi - axios parameter creator
 * @export
 */
var OrganizationProfileControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} orgId
         * @param {ProfileFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationProfileControllerFind: function (orgId, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'orgId' is not null or undefined
                            assertParamExists('organizationProfileControllerFind', 'orgId', orgId);
                            localVarPath = "/organization/{orgId}/profiles"
                                .replace("{" + "orgId" + "}", encodeURIComponent(String(orgId)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * OrganizationProfileControllerApi - functional programming interface
 * @export
 */
var OrganizationProfileControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = OrganizationProfileControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} orgId
         * @param {ProfileFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationProfileControllerFind: function (orgId, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.organizationProfileControllerFind(orgId, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * OrganizationProfileControllerApi - factory interface
 * @export
 */
var OrganizationProfileControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = OrganizationProfileControllerApiFp(configuration);
    return {
        /**
         *
         * @param {number} orgId
         * @param {ProfileFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationProfileControllerFind: function (orgId, filter, options) {
            return localVarFp.organizationProfileControllerFind(orgId, filter, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * OrganizationProfileControllerApi - object-oriented interface
 * @export
 * @class OrganizationProfileControllerApi
 * @extends {BaseAPI}
 */
var OrganizationProfileControllerApi = /** @class */ (function (_super) {
    __extends(OrganizationProfileControllerApi, _super);
    function OrganizationProfileControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} orgId
     * @param {ProfileFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationProfileControllerApi
     */
    OrganizationProfileControllerApi.prototype.organizationProfileControllerFind = function (orgId, filter, options) {
        var _this = this;
        return OrganizationProfileControllerApiFp(this.configuration).organizationProfileControllerFind(orgId, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return OrganizationProfileControllerApi;
}(BaseAPI));
/**
 * PingControllerApi - axios parameter creator
 * @export
 */
var PingControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/ping";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PingControllerApi - functional programming interface
 * @export
 */
var PingControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = PingControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pingControllerPing(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PingControllerApi - factory interface
 * @export
 */
var PingControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = PingControllerApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: function (options) {
            return localVarFp.pingControllerPing(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PingControllerApi - object-oriented interface
 * @export
 * @class PingControllerApi
 * @extends {BaseAPI}
 */
var PingControllerApi = /** @class */ (function (_super) {
    __extends(PingControllerApi, _super);
    function PingControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingControllerApi
     */
    PingControllerApi.prototype.pingControllerPing = function (options) {
        var _this = this;
        return PingControllerApiFp(this.configuration).pingControllerPing(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PingControllerApi;
}(BaseAPI));
/**
 * ProfileControllerApi - axios parameter creator
 * @export
 */
var ProfileControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {NewProfile} [newProfile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreate: function (newProfile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/profiles";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(newProfile, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerDeleteById: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('profileControllerDeleteById', 'id', id);
                            localVarPath = "/profiles/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {ProfileFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFind: function (filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/profiles";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {ProfileFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindById: function (id, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('profileControllerFindById', 'id', id);
                            localVarPath = "/profiles/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {ProfilePartial} [profilePartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateById: function (id, profilePartial, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('profileControllerUpdateById', 'id', id);
                            localVarPath = "/profiles/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(profilePartial, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
var ProfileControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = ProfileControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {NewProfile} [newProfile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreate: function (newProfile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.profileControllerCreate(newProfile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerDeleteById: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.profileControllerDeleteById(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {ProfileFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFind: function (filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.profileControllerFind(filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {ProfileFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindById: function (id, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.profileControllerFindById(id, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} id
         * @param {ProfilePartial} [profilePartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateById: function (id, profilePartial, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.profileControllerUpdateById(id, profilePartial, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ProfileControllerApi - factory interface
 * @export
 */
var ProfileControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = ProfileControllerApiFp(configuration);
    return {
        /**
         *
         * @param {NewProfile} [newProfile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreate: function (newProfile, options) {
            return localVarFp.profileControllerCreate(newProfile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerDeleteById: function (id, options) {
            return localVarFp.profileControllerDeleteById(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {ProfileFilter} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFind: function (filter, options) {
            return localVarFp.profileControllerFind(filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {ProfileFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindById: function (id, filter, options) {
            return localVarFp.profileControllerFindById(id, filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} id
         * @param {ProfilePartial} [profilePartial]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateById: function (id, profilePartial, options) {
            return localVarFp.profileControllerUpdateById(id, profilePartial, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
var ProfileControllerApi = /** @class */ (function (_super) {
    __extends(ProfileControllerApi, _super);
    function ProfileControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {NewProfile} [newProfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    ProfileControllerApi.prototype.profileControllerCreate = function (newProfile, options) {
        var _this = this;
        return ProfileControllerApiFp(this.configuration).profileControllerCreate(newProfile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    ProfileControllerApi.prototype.profileControllerDeleteById = function (id, options) {
        var _this = this;
        return ProfileControllerApiFp(this.configuration).profileControllerDeleteById(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {ProfileFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    ProfileControllerApi.prototype.profileControllerFind = function (filter, options) {
        var _this = this;
        return ProfileControllerApiFp(this.configuration).profileControllerFind(filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {ProfileFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    ProfileControllerApi.prototype.profileControllerFindById = function (id, filter, options) {
        var _this = this;
        return ProfileControllerApiFp(this.configuration).profileControllerFindById(id, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} id
     * @param {ProfilePartial} [profilePartial]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    ProfileControllerApi.prototype.profileControllerUpdateById = function (id, profilePartial, options) {
        var _this = this;
        return ProfileControllerApiFp(this.configuration).profileControllerUpdateById(id, profilePartial, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ProfileControllerApi;
}(BaseAPI));
/**
 * PublicOrganizationControllerApi - axios parameter creator
 * @export
 */
var PublicOrganizationControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {OrganizationFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationControllerFind: function (filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/organizations";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PublicOrganizationControllerApi - functional programming interface
 * @export
 */
var PublicOrganizationControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = PublicOrganizationControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {OrganizationFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationControllerFind: function (filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.publicOrganizationControllerFind(filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PublicOrganizationControllerApi - factory interface
 * @export
 */
var PublicOrganizationControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = PublicOrganizationControllerApiFp(configuration);
    return {
        /**
         *
         * @param {OrganizationFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationControllerFind: function (filter, options) {
            return localVarFp.publicOrganizationControllerFind(filter, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PublicOrganizationControllerApi - object-oriented interface
 * @export
 * @class PublicOrganizationControllerApi
 * @extends {BaseAPI}
 */
var PublicOrganizationControllerApi = /** @class */ (function (_super) {
    __extends(PublicOrganizationControllerApi, _super);
    function PublicOrganizationControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {OrganizationFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicOrganizationControllerApi
     */
    PublicOrganizationControllerApi.prototype.publicOrganizationControllerFind = function (filter, options) {
        var _this = this;
        return PublicOrganizationControllerApiFp(this.configuration).publicOrganizationControllerFind(filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PublicOrganizationControllerApi;
}(BaseAPI));
/**
 * PublicOrganizationMachineControllerApi - axios parameter creator
 * @export
 */
var PublicOrganizationMachineControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {number} orgId
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationMachineControllerFind: function (orgId, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'orgId' is not null or undefined
                            assertParamExists('publicOrganizationMachineControllerFind', 'orgId', orgId);
                            localVarPath = "/organization/{orgId}/machines"
                                .replace("{" + "orgId" + "}", encodeURIComponent(String(orgId)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @param {number} orgId
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationMachineControllerFindRuntime: function (orgId, filter, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'orgId' is not null or undefined
                            assertParamExists('publicOrganizationMachineControllerFindRuntime', 'orgId', orgId);
                            localVarPath = "/organization/{orgId}/machines/runtime"
                                .replace("{" + "orgId" + "}", encodeURIComponent(String(orgId)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication APITokenAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (filter !== undefined) {
                                localVarQueryParameter['filter'] = filter;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PublicOrganizationMachineControllerApi - functional programming interface
 * @export
 */
var PublicOrganizationMachineControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = PublicOrganizationMachineControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {number} orgId
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationMachineControllerFind: function (orgId, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.publicOrganizationMachineControllerFind(orgId, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} orgId
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationMachineControllerFindRuntime: function (orgId, filter, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.publicOrganizationMachineControllerFindRuntime(orgId, filter, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PublicOrganizationMachineControllerApi - factory interface
 * @export
 */
var PublicOrganizationMachineControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = PublicOrganizationMachineControllerApiFp(configuration);
    return {
        /**
         *
         * @param {number} orgId
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationMachineControllerFind: function (orgId, filter, options) {
            return localVarFp.publicOrganizationMachineControllerFind(orgId, filter, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} orgId
         * @param {MachineFilter1} [filter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicOrganizationMachineControllerFindRuntime: function (orgId, filter, options) {
            return localVarFp.publicOrganizationMachineControllerFindRuntime(orgId, filter, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PublicOrganizationMachineControllerApi - object-oriented interface
 * @export
 * @class PublicOrganizationMachineControllerApi
 * @extends {BaseAPI}
 */
var PublicOrganizationMachineControllerApi = /** @class */ (function (_super) {
    __extends(PublicOrganizationMachineControllerApi, _super);
    function PublicOrganizationMachineControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {number} orgId
     * @param {MachineFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicOrganizationMachineControllerApi
     */
    PublicOrganizationMachineControllerApi.prototype.publicOrganizationMachineControllerFind = function (orgId, filter, options) {
        var _this = this;
        return PublicOrganizationMachineControllerApiFp(this.configuration).publicOrganizationMachineControllerFind(orgId, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} orgId
     * @param {MachineFilter1} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicOrganizationMachineControllerApi
     */
    PublicOrganizationMachineControllerApi.prototype.publicOrganizationMachineControllerFindRuntime = function (orgId, filter, options) {
        var _this = this;
        return PublicOrganizationMachineControllerApiFp(this.configuration).publicOrganizationMachineControllerFindRuntime(orgId, filter, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PublicOrganizationMachineControllerApi;
}(BaseAPI));

/* tslint:disable */
/* eslint-disable */
/**
 * thinhoc-api
 * API for ThinHoc Cloud Management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mail@thinhoc.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var Configuration = /** @class */ (function () {
    function Configuration(param) {
        if (param === void 0) { param = {}; }
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.baseOptions = param.baseOptions;
        this.formDataCtor = param.formDataCtor;
    }
    /**
     * Check if the given MIME is a JSON MIME.
     * JSON MIME examples:
     *   application/json
     *   application/json; charset=UTF8
     *   APPLICATION/JSON
     *   application/vnd.company+json
     * @param mime - MIME (Multipurpose Internet Mail Extensions)
     * @return True if the given MIME is JSON, false otherwise.
     */
    Configuration.prototype.isJsonMime = function (mime) {
        var jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
        return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    };
    return Configuration;
}());

export { CMDemoControllerApi, CMDemoControllerApiAxiosParamCreator, CMDemoControllerApiFactory, CMDemoControllerApiFp, Configuration, MachineControllerApi, MachineControllerApiAxiosParamCreator, MachineControllerApiFactory, MachineControllerApiFp, OrganizationBillingControllerApi, OrganizationBillingControllerApiAxiosParamCreator, OrganizationBillingControllerApiFactory, OrganizationBillingControllerApiFp, OrganizationControllerApi, OrganizationControllerApiAxiosParamCreator, OrganizationControllerApiFactory, OrganizationControllerApiFp, OrganizationMgmtTierEnum, OrganizationProfileControllerApi, OrganizationProfileControllerApiAxiosParamCreator, OrganizationProfileControllerApiFactory, OrganizationProfileControllerApiFp, OrganizationWithRelationsMgmtTierEnum, PingControllerApi, PingControllerApiAxiosParamCreator, PingControllerApiFactory, PingControllerApiFp, ProfileControllerApi, ProfileControllerApiAxiosParamCreator, ProfileControllerApiFactory, ProfileControllerApiFp, PublicOrganizationControllerApi, PublicOrganizationControllerApiAxiosParamCreator, PublicOrganizationControllerApiFactory, PublicOrganizationControllerApiFp, PublicOrganizationMachineControllerApi, PublicOrganizationMachineControllerApiAxiosParamCreator, PublicOrganizationMachineControllerApiFactory, PublicOrganizationMachineControllerApiFp };
