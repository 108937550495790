'use strict';

import Peer from 'peerjs';
import dialogs from '../components/dialogs';
import { default as session } from './modules/api/session';
import { sleep } from "@/util";

const MIRROR_PROTOCOL_VERSION = 3;
const MIRROR_CHANNEL_MAX = 8;

const state = {
  server: null,
  initialized: false,
  otc: null,
  peerID_OTC: null,
  peerID_MID: null,
  peerOTC: null,
  peerMID: null,
  peerEstablished: false,
  channel2Call: {},
  calls: [],
  connectedPeers: [],
  autoAcceptTimeout: null
};

/* Return the first free channel or null */
function findFreeChannel() {
  for (let i = 0; i <= MIRROR_CHANNEL_MAX; i++) {
    if(!state.channel2Call[i]) return i;
  }
  return null;
}

function notifyChannelUpdate() {
  const ev = new Event("mirror-stream");
  ev.cmd = 'channelUpdate';
  window.dispatchEvent(ev);
}

function assignCallToChannel(call) {
    //Assign first free channel
    const freeChannel = findFreeChannel();
    console.log('Free channel ' + freeChannel)
    if(freeChannel != null) {
      state.channel2Call[freeChannel] = call;
      //Notify Stream UI of update
      notifyChannelUpdate(); 
    }
}

const getters = {
}

const mutations = {
  async addCall(state, call) {
    //Set default values
    call.nickname = null;
    call.highlight = false;

    state.calls.push(call);

    assignCallToChannel(call);

    //Remove from array once closed
    call.on('close', function() {
      state.calls = state.calls.filter(el => el !== call);
    });
    call.on('error', function(err) {
      console.error(err);
      state.calls = state.calls.filter(el => el !== call);
    });
  },
  swapCallsForChannels(state, payload) {
    const channelA = parseInt(payload.channelA)
    const channelB = parseInt(payload.channelB)
    if(isNaN(channelA) || isNaN(channelB)) return; //One of the streams was not found
    console.debug(`(mirror) Swapping calls for channel ${channelA} and ${channelB}`)
    const callA = state.channel2Call[channelA];
    const callB = state.channel2Call[channelB];
    state.channel2Call[channelA] = callB;
    state.channel2Call[channelB] = callA;
    //Notify Stream UI of update
    notifyChannelUpdate();
  },
  arrangeCalls(state) {
    //Arranges calls to only use the lowest channels
    // e.g. { C0: 1, C1: null, C2: 0} -> { C0: 1, C1: 0, C2: null}
    let calls = Object.values(state.channel2Call).filter(call => call !== null);
    for (let i = 0; i < calls.length; i++) {
      state.channel2Call[i] = calls[i];
    }
    for (let i = calls.length; i <= MIRROR_CHANNEL_MAX; i++) {
      state.channel2Call[i] = null;
    }
    notifyChannelUpdate();
  },
  prependCallForIndex(state, index) {
    const call = state.calls[index];
    if(!call) {
      console.debug("BUG: Trying to prepend call for index that does not exist!");
      return;
    }
    //Place call at channel 0
    const prevCall = state.channel2Call[0];
    state.channel2Call[0] = call;

    //Remove call from previously assigned channel
    for (let i = 1; i <= MIRROR_CHANNEL_MAX; i++) {
      if(state.channel2Call[i] == call) state.channel2Call[i] = null;
    }

    //Place previous call with index 0 to next free slot if there is a free channel    
    const freeChannel = findFreeChannel();
    if(prevCall && (prevCall != call) && freeChannel) {  
      state.channel2Call[freeChannel] = prevCall;
    }

    //Notify Stream UI of update
    notifyChannelUpdate();
  }
}

const actions = {
  getCallForStream(ctx, stream) {
    const calls = ctx.state.calls.filter(el => el.remoteStream === stream);
    if(calls.length) {
      return calls[0]; //Return first call
    }
    return null;
  },
  closeCallForStream(ctx, stream) {
    const calls = ctx.state.calls.filter(el => el.remoteStream === stream);
    calls.forEach(el => {
      closeConnectionsToPeer(el.peer);
      el.close();
    });
  },
  init(ctx) {
    initPJS();
  },
  async reinitPJS(ctx, keepOTC) {
    keepOTC = !!keepOTC;
    state.initialized = false;
    if (!keepOTC) {
      state.otc = null;
    }
    if (testPeerInterval) {
      clearInterval(testPeerInterval);
    }
    if (state.peerMID) {
      state.peerMID.disconnect();
      state.peerMID.destroy();
    }
    if (state.peerOTC) {
      state.peerOTC.disconnect();
      state.peerOTC.destroy();
    }
    await sleep(2000);
    initPJS();
    testPeerInterval = setTestPeerInterval();
  },
  async reconnectPeers(ctx) {
    state.peerOTC.disconnect();
    await sleep(2000);
    state.peerOTC.reconnect();
    state.peerMID.disconnect();
    await sleep(2000);
    state.peerMID.reconnect();
  },
}

const modules = {
}

function closeConnectionsToPeer(peerID) {
  console.debug(`Closing all connections to "${peerID}`)
  var connections = state.peerOTC.connections[peerID] || [];
  if(state.peerMID && state.peerMID.connections[peerID]) {
    connections = connections.concat(state.peerMID.connections[peerID]);
  }

  connections.forEach((conn) => {
    conn.send({cmd: 'bye'});
    conn.close();
  });
}

function closeAllResources(conn) {
  const calls = state.calls.filter(el => el.peer == conn.peer);
  calls.forEach(el => {
    //Remove call from channels
    for (let i = 0; i <= MIRROR_CHANNEL_MAX; i++) {
      if(state.channel2Call[i] == el) state.channel2Call[i] = null;    
    }
    el.close();
  });
  state.calls = state.calls.filter(el => el.peer != conn.peer)
}

async function connectNewPeer(conn) {
  let mirroringSettings = session.state.definition.thinhoc?.mirror;
  let connectionAccepted = false;
  let askConfirmation = () => dialogs.confirm(
        window.vue.$t('mirror.conn-dialog.title'), window.vue.$t('mirror.conn-dialog.message')
    ).then((res) => {
      conn.send({cmd: 'requestShare', response: res});
      connectionAccepted = res;
    });
  let connectWithoutConfirmation = () => conn.send({cmd: 'requestShare', response: true});

  if (!mirroringSettings) {
    await askConfirmation();
    return;
  }
  if (mirroringSettings.accept_new_connections === 'always') {
    connectWithoutConfirmation();
    return;
  }
  if (mirroringSettings.accept_new_connections === 'smart') {
    if (state.connectedPeers.includes(conn.peer)) {
      connectWithoutConfirmation();
      return;
    }
    if (mirroringSettings.auto_accept_timeout.enabled && state.autoAcceptTimeout) {
      connectWithoutConfirmation();
      state.connectedPeers.push(conn.peer);
      return;
    }
    await askConfirmation();
    if (connectionAccepted) {
      if (mirroringSettings.auto_accept_timeout.enabled) {
        state.autoAcceptTimeout = setTimeout(
          () => state.autoAcceptTimeout = null,
          mirroringSettings.auto_accept_timeout.timeout * 60 * 1000
        );
      }
      state.connectedPeers.push(conn.peer);
    }
    return;
  }
  await askConfirmation();
}

function initPeer(peer) {
  peer.on('open', function(id) {
    console.debug("PeerJS signaling connection established: " + id)
    state.peerEstablished = true;
  })

  peer.on('connection', (conn) => {
    conn.on('open', () => {
      conn._lastPing = Date.now() / 1000;
      peer.peerAlive = true;

      conn._pingInterval = setInterval(() => {
        conn.send({'cmd': 'ping'})
        if (conn._lastPing < Date.now() / 1000 - 5) {
          //Five seconds timeout
          if(peer.peerAlive) {
            console.warn("Peer is not alive anymore!")
            closeAllResources(conn);
          }
          peer.peerAlive = false;
        } else {
          peer.peerAlive = true;
        }

      }, 1000);

      conn.on('data', async (data) => {
        try {
          var ev;
          window.console.debug(`Received command "${data.cmd}"\n${JSON.stringify(data)}`);
          switch (data.cmd) {
            case 'ping':
              conn._lastPing = Date.now() / 1000;
              break;
            case 'version':
              conn.send({cmd: 'version', 'response': MIRROR_PROTOCOL_VERSION});
              console.debug("version received")
              break;
            case 'bye':
              closeAllResources(conn);
              conn.close();
              break;
            case 'requestShare':
              await connectNewPeer(conn);
              break;
            case 'requestFullscreen':
              ev = new Event("mirror-stream");
              ev.peer = conn.peer;
              ev.cmd = 'requestFullscreen';
              window.dispatchEvent(ev);
              break;
            case 'requestWhiteboardID':
              const wbid = await pywebview.api.get_whiteboard_id();
              conn.send({cmd: 'requestWhiteboardID', response: wbid});
              break;
            case 'setHandRaised':
              //Set highlight on call object
              for (const call of state.calls) {
                if(call.peer == conn.peer) call.highlight = data.state;
              }
              break;
            case 'setNickname':
                //Set nickname on call object
                for (const call of state.calls) {
                  if(call.peer == conn.peer) call.nickname = data.nickname;
                }
                break;
            default:
              window.console.info(`Received unsupported command ${data.cmd}`);
              conn.send({ cmd: data.cmd, error: 'unsupported' });
          }
        } catch(e) {
          window.console.warn(`Failed to process message from peer "${conn.peer}":\n${JSON.stringify(data)}\n${e}`);
        }
      });
      conn.on('close', function() {
        console.debug(`Connection to "${conn.peer}" closed by peer`)
        if(conn._pingInterval) clearInterval(conn._pingInterval);
        closeAllResources(conn);
      })
    });
  });

  peer.on('call', (call) => {
    // Answer the call, providing our mediaStream
    console.debug('Incoming call:' + call)
    call.answer(null, {sdpTransform: transformSDP});
    mutations.addCall(state, call);
  });

  peer.on('error', (err) => {
    console.error(err);
    console.warn(`Peer is down due to '${err.type}'`);
  })
}

/**
 * Select h264 or VP9 video codec if possible
 *
 * @param sdp {String} SDP protocol description
 * @returns {String}
 */
function transformSDP(sdp) {
  let sdpLines = sdp.split('\n');
  let availableCodecs = [];
  let videoLine = '';
  let h264CodecCode = null;
  for (let i = 0; i < sdpLines.length; i++) {
    let line = sdpLines[i];
    if (line.startsWith('m=video')) {
      videoLine = line;
      continue
    }
    if (line.startsWith('a=rtcp-fb:') || line.startsWith('a=rtpmap:')) {
      let code = line.match(/a=[A-z-]+:([0-9]+) /);
      if (!code) {
        continue;
      }
      code = code[1]
      if (availableCodecs.includes(code)) {
        continue
      }
      if (/a=[A-z-]+:[0-9]+ h264/.test(line)) {
        h264CodecCode = code;
        continue;
      }
      if (/a=[A-z-]+:[0-9]+ VP9/.test(line)) {
        availableCodecs.splice(0, 0, code);
        continue;
      }
      availableCodecs.push(code);
    }
  }
  if (h264CodecCode) {
    availableCodecs.splice(0, 0, h264CodecCode);
  }
  sdp = sdp.replace(/m=video 9 ([A-Z/]+) [0-9 ]+/, `m=video 9 $1 ${availableCodecs.join(' ')}`);
  console.log(sdp);
  return sdp;
}

function getServer() {
  const servers = [
    {
      prefix: 'a0',
      host: 'pjs-a0.ucytech.net',
      secure: true,
    },
    {
      prefix: 'a1',
      host: 'pjs-a1.ucytech.net',
      secure: true,
    },
    {
      prefix: 'a2',
      host: 'pjs-a2.ucytech.net',
      secure: true,
    }
  ]
  return servers[Math.floor(Math.random() * servers.length)]
}

async function getIceServers() {
  try {
    return await pywebview.api.request_mirror_servers();
  } catch(e) {
    console.warn('Failed to get ICE servers:' + e);
    return {}; // Use default
  }
}

async function initPeerMID() {
  // Get ICE Servers via API
  const iceServers = await getIceServers();

  state.peerID_MID = 'thoc-machine-' + await (pywebview.api.get_machine_id());
  state.peerMID = new Peer(state.peerID_MID, {host: 'pjs-a0.ucytech.net', secure: true, pingInterval: 1000, config: { ...iceServers }});
  initPeer(state.peerMID);
}

async function initPeerOTC() {
  const server = state.server;

  // Get ICE Servers via API
  const iceServers = await getIceServers();
  server['config'] = { ...iceServers };

  //Set One-Time-Code
  if (!state.otc) {
    state.otc = (server.prefix + Math.floor(Math.random() * (16777215 - 1118481) + 1118481) //Make sure OTC is at least 0x111111
      .toString(16))
      .toUpperCase();
  }

  //ToDo Save to device
  state.peerID_OTC = 'thoc-otc-' + state.otc;
  state.peerOTC = new Peer(state.peerID_OTC, {...server, pingInterval: 1000});
  initPeer(state.peerOTC);
}

async function initPJS() {
  if(state.initialized) return; //Only initialize once
  state.server = getServer();

  if(typeof pywebview !== 'undefined') {
    initPeerOTC();
    initPeerMID();
  } else {
    window.addEventListener('pywebviewready', initPeerOTC);
    window.addEventListener('pywebviewready', initPeerMID);
  }
  state.initialized = true;
}

initPJS();

let testPeer = new Peer(
    `test-${Math.floor(Math.random() * (16777215 - 1118481) + 1118481).toString(16)}`,
    {host: state.server.host, secure: true, pingInterval: 1000}
);
let testPeerFailed = false;
testPeer.disconnect();
let testPeerInterval = null;

function setTestPeerInterval() {
  if (testPeerInterval) {
    clearInterval(testPeerInterval);
  }
  return setInterval(
      async () => {
        if (!testPeer.disconnected) {
          testPeer.disconnect();
          await sleep(1000);
        }
        testPeer.reconnect();
        testPeerFailed = true;
        await sleep(2000);
        try {
          let conn = testPeer.connect(state.peerOTC.id);
          conn.on('open', function () {
            conn.send({cmd: "version"});
            testPeerFailed = false;
          });
          await sleep(3000);
          conn.close();
        } catch {
          console.log("[mirroring] connection is lost")
        }
        if (testPeerFailed) {
          console.log("[mirroring] reconnecting...")
          await actions.reinitPJS({}, true);
          console.log("[mirroring] reconnected")
        }
        testPeer.disconnect();
      },
      15000,
  )
}

testPeerInterval = setTestPeerInterval();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}