import ptr from 'json-pointer'
import Mustache from 'mustache'
import root_schema from '@/assets/config/rootschema.json'
import { authenticateAPI } from './utils';
import { ajv } from './util-ajv';
import { ProfileControllerApi } from '../../../lib/thinhoc-api';

const state = {
  errorDescription: null,
  appDefinitions: [],
  definition: {},
  definitionTransformed: {},
  deployment: null,
  sessionName: null,
  sessionProfileId: null,
  sessionProfile: {},
  sessionState: "EMPTY",
  _timeout_screensaver: null,
  valid: null,
  validationErrors: null,
}

const getters = {
  autologinEnabled(state) {
    try {
      return state.definitionTransformed.system.auth.mode === "auto-login";
    } catch(e) {
      return false;
    }
  }
}

function generateAppDefinitions(state) {
  console.debug('(store[api/session]) getter: appDefinitions')
  const sessions = []

  const config_sessions = state.definitionTransformed.thinhoc.sessions
  for (const type_ in config_sessions) {

    for (var i = 0; i < config_sessions[type_].length; i++) {
      const session = config_sessions[type_][i]
      var copy = JSON.parse(JSON.stringify(session))
      copy.type = type_
      sessions.push(copy)

    }
  }

  if (sessions.length == 0) {
    const gettingStartedSession = { "type": "webapp", "icon": { "type": "symbol", "symbol": "mdi-lightbulb-on", "symbol_color": "#FFFFFF", "background_color": "#171799" }, "window_title": vue.$i18n.t('global.getting-started'), "display_name": vue.$i18n.t('global.getting-started'), "url": "https://www.thinhoc.com" }
    sessions.push(gettingStartedSession)
  }

  /*if(state.licenseInformation.type == 'non-commercial') {
    const registerClientSession = { "type": "webapp", "icon": "/img/103_thinhoc_app.png", "window_title": "ThinHoc Homepage", "display_name": "Register client", "url": "https://www.thinhoc.com" }
    const buyThinHocSession = { "type": "webapp", "icon": "/img/103_thinhoc_app.png", "window_title": "ThinHoc Homepage", "display_name": "Buy ThinHoc", "url": "https://www.thinhoc.com" }
    sessions.push(registerClientSession)
    sessions.push(buyThinHocSession)
  }*/

  return sessions
}

function renderDefinitionTemplate(template, instance) {
  const view = {
    ...instance
  }
  return JSON.parse(Mustache.render(JSON.stringify(template), view))
}

//TODO: Enhance - For now only add sessions from rendered Definition
function mergeDefinitions(defA, defB) {
  // Merge WebApp Sessions
  try {
    defA.thinhoc.sessions.webapp.push(...defB.thinhoc.sessions.webapp);
  } catch(e) {};
}

function transformSessionDefinition(definition) {
  console.debug('(store[api/session]) transformSessionDefinition')

  const definitionTransformed = JSON.parse(JSON.stringify(definition))

  try {
    console.debug('Enabled apps:' + definition.thinhoc.apps.enabled)
    const enabled = definition.thinhoc.apps.enabled;

    for (const x in enabled) {
      if (Object.hasOwnProperty.call(enabled, x)) {
        const app = enabled[x];
        console.debug(`processing app=${app}`)

        if (!(app in definition.thinhoc.apps.installed)) {
          console.log('app=' + app + ' is not yet installed!');
          continue;
        }

        const template = definition.thinhoc.apps.installed[app].template;

        if (!(app in definition.thinhoc.apps.instances) || (Array.isArray(definition.thinhoc.apps.instances[app]) && definition.thinhoc.apps.instances[app].length === 0)) {
          console.log('app=' + app + ' installed but no instances defined.');
          continue;
        }

        //Instances can be either array or object
        if (Array.isArray(definition.thinhoc.apps.instances[app])) {
          for (const y in definition.thinhoc.apps.instances[app]) {
            if (Object.hasOwnProperty.call(definition.thinhoc.apps.instances[app], y)) {
              const instance = definition.thinhoc.apps.instances[app][y];
              const rendered = renderDefinitionTemplate(template, instance);
              mergeDefinitions(definitionTransformed, rendered);
            }
          }
        } else {
          const instance = definition.thinhoc.apps.instances[app];
          const rendered = renderDefinitionTemplate(template, instance);
          mergeDefinitions(definitionTransformed, rendered);
        }
      }
    }
  } catch (error) {
    console.warn(error)
  }

  //Add/set information from machine context
  const machineContext = store.state.center.machineContext.machineContext
  if (machineContext && definitionTransformed.thinhoc.sip?.enabled) {
    if (machineContext.sip?.enabled) {
      const account = {};
      account.user = machineContext.sip.user;
      if(machineContext.sip.display_name) {
        account.display_name = machineContext.sip.display_name;
      } else {
        account.display_name = machineContext.sip.user;
      }
      account.auth_pass = machineContext.sip.auth_pass;
      if (machineContext.gateway) {
        account.gateway = machineContext.sip.gateway;
      } else {
        account.gateway = definitionTransformed.thinhoc.sip.gateway;
      }
      //Add accounts array if not there
      if(!definitionTransformed.thinhoc.sip.accounts)
        definitionTransformed.thinhoc.sip.accounts = [];
        
      definitionTransformed.thinhoc.sip.accounts.push(account);
    }
  }

  function utf8_to_b64( str ) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }

  //Replace URLs with ThinHoc Cache
  function iterateObject(obj) {
    for (var k in obj) {
        if (typeof obj[k] === "object")
            obj[k] = iterateObject(obj[k]);
        else {
            //Check if property is a string
            if (typeof obj[k] !== 'string') continue;
            //Only replace https://s3.wasabisys.com URLS
            if(!obj[k].startsWith('https://s3.wasabisys.com')) continue;
            obj[k] = 'http://127.0.42.1/' + utf8_to_b64(obj[k]);
        }
    }
    return obj;
  }

  if(typeof pywebview !== 'undefined'/* && await pywebview.api.thinhoc_cache_available()*/) {
    //Replace URLs with ThinHoc Cache URLs if available
    return iterateObject(definitionTransformed);
  } else {
    return definitionTransformed;
  }
}

async function handleScreensaverTimeout() {
  const time_idle = await pywebview.api.get_user_idle_time()
  var timeout = undefined;
  var enabled = false;
  try {
    timeout = state.definition.thinhoc.screensaver.show_after;
    enabled = state.definition.thinhoc.screensaver.enabled;
  } catch (e) {}
  if(!timeout || !enabled) return;
  if(time_idle > timeout && vue.$router.currentRoute.path != 'screensaver') {
    //Start screensaver
    console.log("(session) Starting screensaver!")
    vue.$router.push('/screensaver')
  } else {
    //Wait until timeout can be reached earliest
    console.debug("(session) next screensaver check in " + (timeout - time_idle) + " s")
    this._timeout_screensaver = setTimeout(handleScreensaverTimeout, (timeout - time_idle) * 1000);
  }
}

const mutations = {
  setSessionDefinition(state, profile) {
    console.debug('(store[api/session]) setSessionDefinition profileId=' + profile.id)
    state.sessionProfile = profile
    state.sessionProfileId = profile.id
    state.sessionName = profile.name
    state.definition = profile.definition
    state.deployment = profile.deployment;
    state.definitionTransformed = transformSessionDefinition(state.definition)
    state.appDefinitions = generateAppDefinitions(state)

    //Apply state to local device
    if (typeof pywebview !== 'undefined') {
      pywebview.api.apply_profile({ ...state.sessionProfile, definition: state.definitionTransformed })
    }
    //(Re) initialize screensaver timeout
    mutations.startScreensaverTimeout(state);
  },
  setSessionState(state, sessionState) {
    state.sessionState = sessionState
  },
  validateSessionDefinition(state) {
    console.debug('(store[api/session]) validateSessionDefinition')
    const config_copy = JSON.parse(JSON.stringify(state.definition))
    const ret = ajv.validate(root_schema, config_copy)
    state.definition = config_copy
    state.valid = ret;
    state.validationErrors = ajv.errors;
    if (!ret) {
      console.warn('Validation errors:');
      state.validationErrors.forEach((el) => console.warn(el));
    }
  },
  patchSessionDefinition(state, payload) {
    console.debug('(store[api/session]) patchSessionDefinition: path="' + payload['path'] + '" data="' + JSON.stringify(payload['data']) + '"')
    ptr.set(state.definition, payload['path'], payload['data'])
    state.definitionTransformed = transformSessionDefinition(state.definition)
    state.appDefinitions = generateAppDefinitions(state)
    //Apply state to local device
    if (typeof pywebview !== 'undefined') {
      pywebview.api.apply_profile({ ...state.sessionProfile, definition: state.definitionTransformed })
    }
  },
  startScreensaverTimeout(state) {
    //Delete old timeout
    if(state._timeout_screensaver) {
      clearTimeout(state._timeout_screensaver)
      state._timeout_screensaver = null;
    }
    if(typeof pywebview !== 'undefined') {//Schedule the first call immediately
      handleScreensaverTimeout();
    }
  }
}

const actions = {
  async reloadSession(ctx) {
    ctx.dispatch('loadSession', ctx.state.sessionProfileId || 'local');
  },
  async loadSession(ctx, profileId) {
    await store.dispatch("center/machineContext/load")
    //Timeout
    setTimeout(() => {
      if (ctx.state.sessionState === 'LOADING') {
        ctx.state.errorDescription = vue.$i18n.t('session.timeout-loading')
        ctx.commit('setSessionState', 'ERROR')
      }
    }, 10000);

    ctx.commit('setSessionState', 'LOADING')
    if (profileId === 'local') {
      if (typeof pywebview === 'undefined') {
        //Call as soon es pywebview is defined
        window.addEventListener('pywebviewready', function () {
          ctx.dispatch('loadSession', profileId)
        })
        return;
      }
      //Redirect to center API
      pywebview.api.load_profile()
        .then((res) => {
          ctx.commit('setSessionDefinition', res)
          ctx.commit('validateSessionDefinition')
          ctx.commit('setSessionState', 'LOADED')
        })
        .catch((err) => {
          console.warn(err)
          ctx.state.errorDescription = err.message
          ctx.commit('setSessionState', 'ERROR')
        })
    } else {
      const api = await authenticateAPI(ProfileControllerApi)
      api.profileControllerFindById(profileId)
        .then((res) => {
          console.log(res)
          ctx.commit('setSessionDefinition', res.data)
          ctx.commit('validateSessionDefinition')
          ctx.commit('setSessionState', 'LOADED')
        })
        .catch((err) => {
          console.warn(err)
          ctx.state.errorDescription = err.message
          ctx.commit('setSessionState', 'ERROR')
        })
    }
  },
  async saveSessionDefinition(ctx) {
    const api = await authenticateAPI(ProfileControllerApi)
    await api.profileControllerUpdateById(ctx.state.sessionProfileId, { id: ctx.state.sessionProfileId, definition: ctx.state.definition })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.warn(err)
      })
  },
  patchSessionDefinition(context, payload) {
    context.commit("patchSessionDefinition", payload)
    context.commit("validateSessionDefinition")
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}